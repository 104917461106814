import React from 'react'
import {Link, graphql, useStaticQuery} from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'
import GalleryLayout from '../components/gallery.module.scss'


const Gallery = () => {
    const galleryData = useStaticQuery(graphql`
    query{
        allContentfulGallery{
          edges{
            node{
              title
              slug
              tags
              projectImages{
                file{
                  url
                }
              }
              titleImage{
                file{
                  url
                }
                title
              }
            }
          }
        }
      }
    `)

    const galleryPosts = galleryData.allContentfulGallery.edges;
    
    return(
        <Layout>
            <Head title="Gallery" />
            <h1>Images</h1>
            <p>It is here the visual becomes another means for exploring the ideas about memory. Images to collect perhaps and
             one day to return to. What are we to see but yet another in time past.
            </p>
            <ol className={ GalleryLayout.imgGridIndex}>
                {
                galleryPosts.map(( { node }) => {
                    const title = node.title;
                    const tags = node.tags;
                    const imgURL = node.titleImage.file.url;
                    const altName = node.titleImage.title;
                    return(
                        <li>
                           <Link to= {`/gallery/${node.slug}`}>   
                              <img src={ imgURL }  width={200} alt={ altName }></img>
                            </Link>
                        </li>
                    )
                })
            }
            </ol>
        </Layout>

    )
}

export default Gallery